import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Наші послуги | Clay Court Start</title>
        <meta
          name={"description"}
          content={
            "Створюйте свій ідеальний тенісний досвід, одна подача за раз"
          }
        />
        <meta
          property={"og:title"}
          content={"Наші послуги | Clay Court Start"}
        />
        <meta
          property={"og:description"}
          content={
            "Створюйте свій ідеальний тенісний досвід, одна подача за раз"
          }
        />
        <meta
          property={"og:image"}
          content={"https://top.tovirexstart.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://top.tovirexstart.com/img/images.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://top.tovirexstart.com/img/images.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://top.tovirexstart.com/img/images.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://top.tovirexstart.com/img/images.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://top.tovirexstart.com/img/images.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://top.tovirexstart.com/img/images.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://top.tovirexstart.com/img/images.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-7"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          sm-min-width="280px"
          lg-flex-wrap="wrap"
          display="flex"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          sm-margin="0px 0px 30px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-flex-direction="row"
          lg-flex-wrap="wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
          margin="0px 0px 64px 0px"
        >
          <Text
            as="h1"
            margin="0px 0px 24px 0px"
            font="--headline1"
            color="--dark"
            width="100%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            text-align="left"
            md-text-align="center"
            sm-text-align="left"
          >
            Наші послуги
          </Text>
          <Text
            as="h1"
            margin="0px 0px 0px 0px"
            font="--lead"
            color="--dark"
            width="60%"
            md-width="100%"
            md-margin="0px 0px 32px 0px"
            md-padding="0px 0px 0px 0px"
            text-align="left"
            md-text-align="center"
            lg-width="100%"
            sm-text-align="left"
          >
            У Clay Court Start ми прагнемо надавати широкий спектр послуг
            найвищого рівня, покликаних покращити ваш досвід гри в теніс. Від
            початківця до досвідченого професіонала, наші комплексні пропозиції
            гарантують, що кожен гість знайде свій ритм на корті. Ознайомтеся з
            деталями наших послуг нижче та дізнайтеся, як ми можемо покращити
            вашу гру.
          </Text>
        </Box>
        <Box
          width="100%"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="48px"
          lg-grid-gap="48px"
          lg-width="100%"
          sm-grid-template-columns="1fr"
          lg-grid-template-columns="repeat(2, 1fr)"
        >
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
              Оренда кортів
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Бездоганні глиняні корти: Забронюйте час для гри на наших
              бездоганно доглянутих глиняних кортах, створених для оптимальної
              гри.
              <br />
              <br />
              Освітлення для нічних матчів: Продовжуйте грати у вечірній час під
              нашим екологічно чистим освітленням.
              <br />
              <br />
              Водні станції: Залишайтеся гідратованими завдяки зручно
              розташованим водним станціям навколо кожного корту.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Оренда спорядження
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Високоякісне спорядження: Доступ до широкого асортименту тенісного
              спорядження, від ракеток до м'ячів, першокласної якості.
              <br />
              <br />
              Вибір ракеток: Обирайте серед різноманітних ракеток, що підходять
              для різних стилів гри та рівнів майстерності.
              <br />
              <br />
              Зручне взуття: Орендуйте спеціально розроблене взуття для
              глиняного корту для кращої продуктивності та безпеки.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Тренувальні програми
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Індивідуальні тренування: покращуйте свою гру з нашими
              досвідченими тренерами, які пропонують індивідуальні тренування.
              <br />
              <br />
              Групові заняття: Приєднуйтесь до наших групових занять, щоб
              навчатися в динамічному, сприятливому середовищі.
              <br />
              <br />
              Приватні уроки: Вибирайте індивідуальні тренування для
              цілеспрямованого вдосконалення навичок.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Організація матчів
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Знайди свою пару: Ми допомагаємо об'єднати гравців з однаковим
              рівнем майстерності для змагальних та дружніх матчів.
              <br />
              <br />
              Соціальні турніри: Беріть участь у наших регулярних турнірах
              спільноти, щоб розважитися і прославитися.
              <br />
              <br />
              Гра в лігах: Для тих, хто прагне регулярних змагань, приєднуйтесь
              до однієї з наших численних тенісних ліг.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Спеціальні події
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Проводьте свої заходи: Наші корти та приміщення доступні для
              проведення приватних заходів, днів народження та корпоративних
              зустрічей.
              <br />
              <br />
              Кейтерингові послуги: Насолоджуйтесь індивідуальними варіантами
              кейтерингу для вашого заходу, від закусок до повноцінних обідів.
              <br />
              <br />
              Підтримка у плануванні заходів: Дозвольте нам допомогти вам
              організувати кожну деталь вашого заходу, щоб він пройшов
              бездоганно.
            </Text>
          </Box>
          <Box
            align-items="flex-start"
            display="flex"
            flex-direction="column"
            sm-align-items="flex-start"
          >
            <Text
              font="--headline3"
              color="--dark"
              margin="0px 0px 6px 0px"
              sm-text-align="left"
            >
              Pro Shop
            </Text>
            <Text
              margin="8px 0px 0px 0px"
              font="--base"
              color="--dark"
              sm-text-align="left"
            >
              Роздрібна торгівля тенісом: У нашому магазині представлені
              найновіші моделі тенісного одягу, спорядження та аксесуарів.
              <br />
              <br />
              Поради експертів: Отримайте пораду від кваліфікованого персоналу,
              щоб вибрати найкраще спорядження для ваших потреб.
              <br />
              <br />
              Обслуговування обладнання: Скористайтеся нашими послугами з
              нанизування та обслуговування ракеток.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://top.tovirexstart.com/img/5.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Дізнайтеся більше на сайті Clay Court Start
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
           Хочете дізнатися більше про наші послуги або готові забронювати свою наступну тенісну пригоду? Зв'яжіться з нами або відвідайте Clay Court Start особисто, щоб отримати вичерпну інформацію про все, що ми пропонуємо. Наша команда прагне забезпечити, щоб кожен візит відповідав вашим потребам, допомагаючи вам створити незабутні тенісні моменти. Незалежно від того, чи прагнете ви вдосконалити свою гру, взяти участь у товариських матчах або просто насолодитися унікальним досвідом тенісу на глиняному корті, ми тут, щоб служити вам.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline2"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
           Покращуйте свою гру на ґрунтовому корті Clay Court Start. Зв'яжіться з нами, щоб розблокувати вашу максимальну продуктивність.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Розпочати навчання
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://top.tovirexstart.com/img/6.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
